import styled from 'styled-components';

interface ButtonRootProps {
  variant: 'primary' | 'success' | 'error' | 'secondary';
}

const handleBackgroundColor = (type: string) => {
  switch (type) {
    case 'error':
      return '#f44336';
    case 'success':
      return '#04AA6D';
    case 'secondary':
      return '#757575';
    case 'primary':
    default:
      return '#274492';
  }
};

export const ButtonRoot = styled.button<ButtonRootProps>`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 2.813rem;

  background: ${(props) => handleBackgroundColor(props.variant)};
  border: none;
  border-radius: 0.25rem;

  font-family: 'Bree Serif', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #ffffff;
`;
