import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from '@Context/GlobalContext';

import { Routes } from '@Helper/Routes';
import { errorToast, successToast } from '@Helper/toastHelper';
import { useQuery } from '@Helper/useQuery';

enum EmailUpdateCompleteState {
  completed = 'completed',
  notFound = 'not_found',
  invalid = 'invalid',
}

const EmailUpdateComplete = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { loggedIn } = useGlobalContext();

  const handleRedirect = () => {
    if (loggedIn) {
      navigate(Routes.Account);
      return;
    }

    navigate(Routes.Login);
  };

  useEffect(() => {
    const emailUpdateState = query.get('email-update');

    if (!emailUpdateState) {
      return;
    }

    if (emailUpdateState === EmailUpdateCompleteState.completed) {
      successToast('E-Mail wurde erfolgreich geändert');
      handleRedirect();
      return;
    }

    if (emailUpdateState === EmailUpdateCompleteState.invalid) {
      errorToast(
        'Die E-Mail Update Anfrage ist veraltet und nicht mehr gültig, bitte versuch es erneut.'
      );
      handleRedirect();
      return;
    }

    if (emailUpdateState === EmailUpdateCompleteState.notFound) {
      errorToast(
        'Es wurde keine gültige Email Update Anfrage gefunden, bitte versuch es erneut.'
      );
      handleRedirect();
      return;
    }

    errorToast(`Unexpected login status [${emailUpdateState}] received`);
    handleRedirect();
  }, []);

  return <></>;
};

export default EmailUpdateComplete;
