import type { ApiResultModel } from './ApiResultModel';

type body = {
  code?: number;
  message?: string;
  data?: any;
};

export class ApiErrorModel extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: body | any;

  constructor(response: ApiResultModel, message: string) {
    super(message);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;

    if (typeof this.body.code !== 'number') {
      this.body.code = 0;
    }
  }

  public isGenericError(): boolean {
    return this.message === 'Generic Error';
  }
}
