import styled from 'styled-components';

interface StyledInputProps {
  isError: boolean;
}

interface LabelProps {
  isError: boolean;
}

export const Root = styled.div`
  margin-bottom: 1rem;
`;

export const StyledInput = styled.input<StyledInputProps>`
  font-family: 'Bree Serif', serif;

  box-sizing: border-box;
  width: 100%;

  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  font-size: 1rem;

  background: #f8f8f8;
  border: 1px solid ${(props) => (props.isError ? '#ff3333' : '#D8D8D8')};
  border-radius: 4px;
`;

export const Label = styled.label<LabelProps>`
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  color: ${(props) => (props.isError ? '#ff3333' : '#000')};
`;

export const Error = styled.p`
  width: 100%;
  font-size: 0.75rem;
  color: #ff3333;
  margin-top: 0;
  margin-bottom: 0;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  margin-left: 0.5rem;
`;
