import { IconCaretDown, IconCaretUp } from '@tabler/icons';
import React, { useState } from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { FaqLi, LiContainer } from './DonationFaq.styles';

interface Props extends PropsWithClassName {}

const DonationFaq = (props: Props): React.ReactElement => {
  const [faq1Open, setFaq1Open] = useState<boolean>(false);
  const [faq2Open, setFaq2Open] = useState<boolean>(false);

  return (
    <div>
      FAQs: <br />
      <ul>
        <FaqLi onClick={() => setFaq1Open(!faq1Open)}>
          <LiContainer>
            Wie kommen Sie an einen Spendenbeleg?
            {faq1Open ? <IconCaretUp /> : <IconCaretDown />}
          </LiContainer>
        </FaqLi>
        {faq1Open && (
          <p>
            Für alle über dieses Onlinetool abgegebenen Spenden erhalten Sie im
            Februar/März kommenden Jahres eine automatisierte
            Spendenbescheinigung per Email (sofern Sie eine Email-Adresse
            angeben).
          </p>
        )}
        <FaqLi onClick={() => setFaq2Open(!faq2Open)}>
          <LiContainer>
            Wünschen Sie schon vorher einen Beleg?
            {faq2Open ? <IconCaretUp /> : <IconCaretDown />}
          </LiContainer>
        </FaqLi>
        {faq2Open && (
          <p>
            Für Spenden bis zu 300 Euro hat der Gesetzgeber die Nachweispflicht
            in den letzten Jahren stark vereinfacht. Eine klassische
            “Spendenquittung” ist nicht mehr notwendig. Bei Beiträgen bis zu 300
            Euro dient bereits Ihr Kontoauszug in Kombination mit dem
            Freistellungstext unserer Organisation (diesen finden Sie bei den
            Download-Formularen) dem Finanzamt als gültiger Nachweis.
            <br />
            <br /> Bei höheren Beträgen senden wir Ihnen gerne eine von uns
            individuell erstellte Bescheinigung zu. Hierzu bitten wir Sie, uns
            Ihre Postanschrift zu übermitteln (im Überweisungsformular oder per
            Email). Gerne senden wir Ihnen auch bei kleineren Beiträgen eine
            solche individuelle Bescheinigung aus, sofern Sie dies ausdrücklich
            wünschen.
          </p>
        )}
      </ul>
    </div>
  );
};

export { DonationFaq };
