import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import { useRequest } from '@Api/useRequest';

import { useGlobalContext } from '@Context/GlobalContext';

import Button from '@Components/Button/Button';
import Container, { maxWidth } from '@Components/Container/Container';
import { Form } from '@Components/Form/Form';
import Input from '@Components/Input/Input';

import { Routes } from '@Helper/Routes';
import { apiErrorToast, errorToast, successToast } from '@Helper/toastHelper';
import { useQuery } from '@Helper/useQuery';

import { StyledLink } from './RegisterComplete.styles';

const RegisterComplete = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { loggedIn, logout } = useGlobalContext();

  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');

  useEffect(() => {
    const token = query.get('registrationToken');

    if (!loggedIn) {
      return;
    }

    if (token !== null) {
      logout();
      return;
    }

    navigate(Routes.Dashboard);
  }, []);

  const { loading, executeRequest, validationError } =
    useRequest<DefaultResponseModel>(
      getBackendRoute(ApiRoutes.registerComplete),
      'POST',
      DefaultResponseModel.hydrateFromJson,
      () => {
        successToast('Registrierung erfolgreich, logge dich jetzt ein!');
        navigate(Routes.Login);
      },
      (error) => {
        apiErrorToast(error);
      },
      (validationError) => {
        if (validationError.hasError('registrationToken')) {
          const tokenErrorMessages =
            validationError.getForField('registrationToken');
          tokenErrorMessages.forEach((tokenErrorMessage) =>
            errorToast(tokenErrorMessage.message)
          );
        }
      }
    );

  const completeRegistration = async () => {
    const data = {
      registrationToken: query.get('registrationToken'),
      password: password,
      passwordRepeat: passwordRepeat,
    };

    await executeRequest(data);
  };

  return (
    <Container maxWidth={maxWidth.s}>
      <p>Willkommen zum wünschdirwas Radel-Profil</p>
      <h3>Schließe jetzt deine Registrierung ab:</h3>
      <Form onSubmit={() => completeRegistration()}>
        <Input
          type="password"
          value={password}
          onChange={(password) => setPassword(password)}
          label="Passwort"
          placeholder="Hier kannst du dein Passwort vergeben"
          error={validationError?.getForField('password') ?? []}
          required={true}
        />
        <Input
          type="password"
          value={passwordRepeat}
          onChange={(passwordRepeat) => setPasswordRepeat(passwordRepeat)}
          label="Passwort bestätigen"
          placeholder="Bitte bestätige hier dein Passwort"
          error={validationError?.getForField('passwordRepeat') ?? []}
          required={true}
        />
        <Button loading={loading} type="submit">
          Registrierung abschließen
        </Button>
        <StyledLink onClick={() => navigate(Routes.Login)}>
          oder logge dich mit deinem bestehenden Profil ein
        </StyledLink>
      </Form>
    </Container>
  );
};

export default RegisterComplete;
