import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGlobalContext } from '@Context/GlobalContext';

import Link from '@Components/Link/Link';

import { PropsWithClassName } from '@Helper/PropsWithClassName';
import { Routes } from '@Helper/Routes';

import {
  LinkContainerLeft,
  LinkContainerRight,
  Root,
  StyledWdwLogo,
} from './Header.styles';

interface Props extends PropsWithClassName {}

const Header = (props: Props): React.ReactElement => {
  const { loggedIn, logout } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      <LinkContainerLeft>
        {location.pathname !== Routes.Dashboard && loggedIn && (
          <Link onClick={() => navigate(Routes.Dashboard)}>Startseite</Link>
        )}
      </LinkContainerLeft>
      <StyledWdwLogo />
      <LinkContainerRight>
        {loggedIn && <Link onClick={logout}>Logout</Link>}
      </LinkContainerRight>
    </Root>
  );
};

export default Header;
