import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledImage = styled.img`
  cursor: pointer;
  margin: 25px;
  max-width: 300px;
`;
