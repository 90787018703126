export default class ImageModel {
  public id: string;
  public createdAt: string;
  public updatedAt: string;
  public file: string;

  constructor(id: string, createdAt: string, updatedAt: string, file: string) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.file = file;
  }

  public static hydrateFromJson(data: any) {
    return new ImageModel(data.id, data.createdAt, data.updatedAt, data.file);
  }
}
