import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './assets/styles/styles.scss';
import GlobalContextProvider from './context/GlobalContextProvider';
import './index.scss';
import Routing from './pages/Routing';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GlobalContextProvider>
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  </GlobalContextProvider>
);
