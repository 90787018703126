import { toast } from 'react-toastify';

import { ApiErrorModel } from '@Api/models/ApiErrorModel';

export function successToast(message: string) {
  toast(message, {
    type: toast.TYPE.SUCCESS,
  });
}

export function errorToast(message: string) {
  toast(message, {
    type: toast.TYPE.ERROR,
    autoClose: false,
  });
}

export function apiErrorToast(error: ApiErrorModel) {
  let message = error.body.message ? error.body.message : error.message;

  if (error.status === 403 || error.status === 401) {
    message = error.body.message;
  }

  toast(message, {
    type: toast.TYPE.ERROR,
    autoClose: false,
  });
}
