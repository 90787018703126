import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { Divider, LinkContainer, Root, StyledLink } from './Footer.styles';

interface Props extends PropsWithClassName {}

const Footer = (props: Props): React.ReactElement => {
  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      <Divider />
      <LinkContainer>
        <StyledLink
          href="https://wuenschdirwas.de/service/impressum/"
          target="_blank"
        >
          Impressum
        </StyledLink>
        <StyledLink
          href="https://wuenschdirwas.de/service/datenschutz/"
          target="_blank"
        >
          Datenschutz
        </StyledLink>
      </LinkContainer>
    </Root>
  );
};

export default Footer;
