import React, { useState } from 'react';

import ImagePreview from '@Components/ImagePreview/ImagePreview';
import ImageSlider from '@Components/ImageSlider/ImageSlider';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { EmptyText, Root } from './ImageBox.styles';

interface Props extends PropsWithClassName {
  files?: File[];
  images?: string[];
  onDelete?: (index: number) => void;
  emptyMessage: string;
}

const ImageBox = (props: Props): React.ReactElement => {
  const { files, onDelete, images, emptyMessage } = props;

  const [galleryIsOpen, setGalleryIsOpen] = useState<boolean>(false);
  const [galleryIndex, setGalleryIndex] = useState<number>(0);

  let currentImages = images ?? [];

  if (files) {
    currentImages = files.map((file) => URL.createObjectURL(file));
  }

  const renderImages = () => {
    if (currentImages.length === 0) {
      return <EmptyText>{emptyMessage}</EmptyText>;
    }

    return currentImages.map((image, index) => {
      return (
        <ImagePreview
          key={index}
          imageUrl={image}
          index={index}
          openGallery={(index) => {
            setGalleryIsOpen(true);
            setGalleryIndex(index);
          }}
          onDelete={onDelete}
        />
      );
    });
  };

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      {renderImages()}
      {galleryIsOpen && (
        <ImageSlider
          images={currentImages}
          index={galleryIndex}
          onClose={() => setGalleryIsOpen(false)}
        />
      )}
    </Root>
  );
};
export default ImageBox;
