import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Root = styled.div`
  border-radius: 50%;
  height: 1em;
  width: 1em;
  margin-left: 1rem;
  transition: opacity 250ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  transition-delay: 250ms;

  border: 4px solid rgba(255, 255, 255, 0.5);
  border-top-color: #fff;
`;
