import { IconX } from '@tabler/icons';
import React, { MouseEvent, ReactNode } from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import {
  CloseIcon,
  Content,
  ContentWindow,
  Headline,
  Root,
} from './Modal.styles';

interface Props extends PropsWithClassName {
  minWidth?: number;
  minHeight?: number;
  onClose?: () => void;
  headline?: string;
  children: ReactNode;
}

const Modal = (props: Props): React.ReactElement => {
  const { className, minWidth, minHeight, children, onClose, headline } = props;

  const backdropRef = React.createRef<HTMLDivElement>();

  const handleClose = () => {
    if (!onClose) {
      return;
    }

    onClose();
  };

  const handleBackdropClick = (ev: MouseEvent) => {
    if (ev.nativeEvent.target !== backdropRef.current) {
      return;
    }

    handleClose();
  };

  return (
    <Root
      ref={backdropRef}
      className={className}
      data-testid={'Modal-root'}
      onClick={handleBackdropClick}
    >
      <ContentWindow minWidth={minWidth ?? 800} minHeight={minHeight ?? 400}>
        <CloseIcon>
          <IconX size={30} onClick={handleClose} />
        </CloseIcon>
        <Headline>{headline}</Headline>
        <Content>{children}</Content>
      </ContentWindow>
    </Root>
  );
};

export default Modal;
