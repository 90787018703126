export enum ApiRoutes {
  registerComplete = '/register/complete',
  passwordRecover = '/password/recovery',
  passwordReset = '/password/reset',
  login = '/login',
  getAccount = '/account/user',
  updateTeamName = '/team/update/name',
  updateUserName = '/account/update/name',
  updateUserEmail = '/account/update/email',
  updateUserPassword = '/account/update/password',
  getTeam = '/team',
  updateTeam = '/team/update',
  createArticle = '/article/create',
  uploadImages = '/image/upload',
  updateArticle = '/article/update',
  getArticle = '/article',
  listArticles = '/articles',
}

export function getBackendRoute(route: ApiRoutes) {
  return process.env.REACT_APP_API_ROOT_URL + route;
}
