import React, { useEffect, useState } from 'react';

import ValidationErrorModel from '@Api/models/ValidationErrorModel';

import Alert from '@Components/Alert/Alert';
import AlertModal from '@Components/AlertModal/AlertModal';
import ImageBox from '@Components/ImageBox/ImageBox';
import Input from '@Components/Input/Input';
import InputArea from '@Components/InputArea/InputArea';
import InputDropzone from '@Components/InputDropzone/InputDropzone';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import {
  ArticleLink,
  ArticleLinkContainer,
  ButtonGroup,
  LoadingBox,
  Root,
  StyledButton,
  StyledLoadingSpinner,
} from './ArticleEditBox.styles';

interface Props extends PropsWithClassName {
  update: (
    title: string,
    content: string,
    status: 'draft' | 'published',
    files: File[]
  ) => void;
  initTitle?: string;
  initContent?: string;
  initFiles?: File[];
  isPublished?: boolean;
  articleLink?: string;
  updateValidationError?: ValidationErrorModel | null;
  loading?: boolean;
}

const ArticleEditBox = (props: Props): React.ReactElement => {
  const {
    update,
    initTitle,
    initContent,
    initFiles,
    isPublished,
    articleLink,
    updateValidationError,
    loading,
  } = props;

  const [alertModalIsOpen, setAlertModalIsOpen] = useState<boolean>(false);
  const [alertModalType, setAlertModalType] = useState<'draft' | 'publish'>(
    'draft'
  );
  const [title, setTitle] = useState<string>(initTitle ?? '');
  const [content, setContent] = useState<string>(initContent ?? '');
  const [files, setFiles] = useState<File[]>(initFiles ?? []);
  const [fileLengthError, setFileLengthError] = useState<boolean>(false);

  const maxFileLength = 3;

  useEffect(() => {
    if (initFiles) {
      setFiles(initFiles);
    }
  }, [initFiles]);

  useEffect(() => {
    if (files.length < maxFileLength) {
      setFileLengthError(false);
    }

    if (files.length <= maxFileLength) {
      return;
    }

    setFiles(files.filter((file, index) => index < maxFileLength));
    setFileLengthError(true);
  }, [files]);

  const handleDelete = (deleteIndex: number) => {
    setFiles(files.filter((file, index) => index !== deleteIndex));
  };

  const clearInputs = () => {
    if (!initTitle) {
      setTitle('');
    }
    if (!initContent) {
      setContent('');
    }
    if (!initFiles) {
      setFiles([]);
    }
    setFileLengthError(false);
  };

  const renderExecuteModal = () => {
    if (alertModalType === 'publish') {
      return (
        <AlertModal
          onAccept={() => {
            setAlertModalIsOpen(false);
            update(title, content, 'published', files);
            clearInputs();
          }}
          onDecline={() => {
            setAlertModalIsOpen(false);
          }}
          text="Artikel mit wünschdirwas teilen?"
        />
      );
    }

    return (
      <AlertModal
        onAccept={() => {
          setAlertModalIsOpen(false);
          update(title, content, 'draft', files);
          clearInputs();
        }}
        onDecline={() => {
          setAlertModalIsOpen(false);
        }}
        text="Artikel als Entwurf speichern?"
      />
    );
  };

  const renderDropzone = () => {
    if (loading) {
      return (
        <LoadingBox>
          <StyledLoadingSpinner />
          <div>Artikel wird gespeichert...</div>
        </LoadingBox>
      );
    }

    if (files.length >= maxFileLength || isPublished) {
      return <></>;
    }

    return (
      <InputDropzone
        onDrop={(newFiles) => setFiles([...files, ...newFiles])}
        text="ziehe hier deine Bilder rein oder klicke hier um maximal 3 Bilder
              hochzuladen"
      />
    );
  };

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      <Input
        label="Artikel-Überschrift"
        placeholder="Gib hier deine Artikel-Überschrift ein"
        value={title}
        onChange={(value) => setTitle(value)}
        type="text"
        disabled={isPublished}
        error={updateValidationError?.getForField('title') ?? []}
      />
      <InputArea
        label="Artikel Inhalt"
        placeholder="In diesem Bereich kannst du über eure Erlebnisse berichten.
        Wenn du magst, kannst du dazu gerne bis zu 3 Bilder beifügen.
        Mach dir keine Sorgen über die Formatierung des Artikels,
        wir kümmern uns vor Veröffentlichung darum."
        value={content}
        onChange={(value) => setContent(value)}
        max={5000}
        disabled={isPublished}
        error={updateValidationError?.getForField('content') ?? []}
      />
      {fileLengthError && (
        <Alert type="error">
          Es ist leider nicht möglich mehr als 3 Bilder hochzuladen
        </Alert>
      )}
      {files.length > 0 && (
        <ImageBox files={files} onDelete={handleDelete} emptyMessage="" />
      )}
      {renderDropzone()}
      {alertModalIsOpen && renderExecuteModal()}
      {isPublished !== true && (
        <ButtonGroup>
          <StyledButton
            variant="secondary"
            onClick={() => {
              setAlertModalIsOpen(true);
              setAlertModalType('draft');
            }}
          >
            als Entwurf speichern
          </StyledButton>
          <StyledButton
            variant="success"
            onClick={() => {
              setAlertModalIsOpen(true);
              setAlertModalType('publish');
            }}
          >
            Artikel einreichen
          </StyledButton>
        </ButtonGroup>
      )}
      {articleLink && (
        <ArticleLinkContainer>
          <ArticleLink href={articleLink} target="_blank">
            Zum Artikel
          </ArticleLink>
        </ArticleLinkContainer>
      )}
    </Root>
  );
};

export default ArticleEditBox;
