import {
  IconAlertCircle,
  IconCircleCheck,
  IconCircleX,
  IconInfoCircle,
} from '@tabler/icons';
import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { AlertRoot, Icon } from './Alert.styles';

interface Props extends PropsWithClassName {
  type: 'error' | 'success' | 'warning' | 'info';
  children: string;
}

const Alert = (props: Props): React.ReactElement => {
  const { type, children } = props;

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <IconCircleX />;
      case 'success':
        return <IconCircleCheck />;
      case 'warning':
        return <IconAlertCircle />;
      case 'info':
      default:
        return <IconInfoCircle />;
    }
  };

  return (
    <AlertRoot
      className={props.className}
      data-testid={props.dataTestId}
      type={type}
    >
      <Icon>{getIcon()}</Icon>
      {children}
    </AlertRoot>
  );
};

export default Alert;
