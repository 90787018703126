import React from 'react';

import { ValidationMessage } from '@Api/models/ValidationErrorModel';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import {
  ButtonContainer,
  Error,
  InputRow,
  Label,
  Root,
  StyledInput,
} from './Input.styles';

interface Props extends PropsWithClassName {
  value: string;
  onChange: (value: string) => void;
  type: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  error?: ValidationMessage[];
  button?: React.ReactElement;
  disabled?: boolean;
}

const Input = (props: Props): React.ReactElement => {
  const {
    value,
    type,
    onChange,
    label,
    placeholder,
    error,
    required,
    button,
    disabled,
  } = props;

  const isError = error !== undefined && error.length > 0;

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      {label && <Label isError={isError}>{label}</Label>}
      <InputRow>
        <StyledInput
          value={value}
          onChange={(event) => onChange(event.target.value)}
          type={type}
          placeholder={placeholder}
          isError={isError}
          required={required ?? false}
          disabled={disabled ?? false}
        />
        {button && <ButtonContainer>{button}</ButtonContainer>}
      </InputRow>
      {error &&
        error.map((message: ValidationMessage) => (
          <Error key={message.key}>{message.message}</Error>
        ))}
    </Root>
  );
};

export default Input;
