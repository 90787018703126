import styled from 'styled-components';

interface StyledTextAreaProps {
  isError: boolean;
}

interface LabelProps {
  isError: boolean;
}

export const Root = styled.div`
  margin-bottom: 1rem;

  max-width: 100%;
  max-height: 500px;
`;

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  font-family: 'Bree Serif', serif;

  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 400px;

  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  font-size: 1rem;

  background: #f8f8f8;
  border: 1px solid ${(props) => (props.isError ? '#ff3333' : '#D8D8D8')};
  border-radius: 4px;
`;

export const Label = styled.label<LabelProps>`
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  color: ${(props) => (props.isError ? '#ff3333' : '#000')};
`;

export const LengthInfo = styled.p`
  color: #757575;
  margin: 0;
  font-size: 14px;
  text-align: end;
`;

export const Error = styled.p`
  width: 100%;
  font-size: 0.75rem;
  color: #ff3333;
  margin-top: 0;
  margin-bottom: 0;
`;
