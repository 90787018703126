import { createContext, useContext } from 'react';

export type GlobalContent = {
  isLoading: boolean;
  authorizationHeader: string | undefined;
  loggedIn: boolean;
  login: (authorizationHeader: string) => void;
  logout: () => void;
};

export const MyGlobalContext = createContext<GlobalContent>({
  isLoading: true,
  authorizationHeader: '',
  loggedIn: false,
  login: (authorizationHeader: string) => {
    return;
  },
  logout: () => {
    return;
  },
});

export const useGlobalContext = () => useContext(MyGlobalContext);
