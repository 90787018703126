import styled from 'styled-components';

export const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row;
  z-index: 10001;
`;

export const ContentWindow = styled.div`
  margin: auto;
  width: 100%;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
  padding-top: 1rem;
  padding-right: 1rem;
`;
