import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { DeleteButton, Preview, Root } from './ImagePreview.styles';

interface Props extends PropsWithClassName {
  imageUrl: string;
  openGallery: (index: number) => void;
  index: number;
  onDelete?: (index: number) => void;
}

const ImagePreview = (props: Props): React.ReactElement => {
  const { imageUrl, openGallery, index, onDelete } = props;

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      {onDelete && <DeleteButton size={20} onClick={() => onDelete(index)} />}
      <Preview onClick={() => openGallery(index)} src={imageUrl} />
    </Root>
  );
};
export default ImagePreview;
