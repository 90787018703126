import { useEffect, useState } from 'react';

import { ApiErrorModel } from '@Api/models/ApiErrorModel';
import { ApiResultModel } from '@Api/models/ApiResultModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';

import { useGlobalContext } from '@Context/GlobalContext';

export function useRequest<ResponseModel>(
  url: string,
  method: 'POST' | 'GET',
  hydrate: (response: any) => ResponseModel,
  onSuccess?: (result: ResponseModel) => void,
  onError?: (result: ApiErrorModel) => void,
  onValidationError?: (result: ValidationErrorModel) => void
) {
  const { authorizationHeader } = useGlobalContext();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<ResponseModel | null>(null);
  const [error, setError] = useState<ApiErrorModel | null>(null);
  const [validationError, setValidationError] =
    useState<ValidationErrorModel | null>(null);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (result) {
      if (onSuccess) {
        onSuccess(result);
      }
      return;
    }
    if (validationError) {
      if (onValidationError) {
        onValidationError(validationError);
      }
      return;
    }
    if (error) {
      if (onError) {
        onError(error);
      }
    }
  }, [result, error, loading, validationError]);

  async function getHeaders(): Promise<Headers> {
    const defaultHeaders = Object.entries({
      'Content-Type': 'application/json',
    });

    const headers = new Headers(defaultHeaders);

    if (authorizationHeader) {
      headers.append('X-Authorization', `Bearer ${authorizationHeader}`);
    }

    return headers;
  }

  const executeRequest = async function (data?: any) {
    setLoading(true);
    setValidationError(null);
    setError(null);
    setResult(null);

    let requestUrl = url;

    if (method === 'GET' && data) {
      requestUrl = requestUrl + '/' + data;
    }

    try {
      const headers = await getHeaders();

      const response = await fetch(requestUrl, {
        method: method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: headers,
        body: data && method === 'POST' ? JSON.stringify(data) : null,
      });

      const responseBody = await getResponseBody(response);

      const result: ApiResultModel = {
        url: requestUrl,
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        body: responseBody,
      };

      catchErrors(result);

      setResult(hydrate(result.body));
    } catch (error: any) {
      if (error instanceof ValidationErrorModel) {
        setValidationError(error);
      }
      if (error instanceof ApiErrorModel) {
        setError(error);
      }
    }

    setLoading(false);
  };
  return { loading, result, error, validationError, executeRequest };
}

function catchErrors(result: ApiResultModel): void {
  const errors: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    422: 'Unprocessable Entity',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
  };

  const error = errors[result.status];

  if (error && result.status === 422) {
    throw new ValidationErrorModel(
      result.body.code,
      result.body.data,
      result.body.message
    );
  }

  if (error) {
    throw new ApiErrorModel(result, error);
  }

  if (!result.ok) {
    throw new ApiErrorModel(result, 'Generic Error');
  }
}

async function getResponseBody(response: Response): Promise<any> {
  if (response.status !== 204) {
    try {
      const contentType = response.headers.get('Content-Type');
      if (contentType) {
        const isJSON = contentType.toLowerCase().startsWith('application/json');
        if (isJSON) {
          return await response.json();
        } else {
          return await response.text();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  return;
}
