import React from 'react';
import { useNavigate } from 'react-router-dom';

import Container, { maxWidth } from '@Components/Container/Container';

import { Routes } from '@Helper/Routes';

import {
  CardContainer,
  DataCard,
  DiaryCard,
  DonationCard,
  ImageUploadCard,
} from './Dashboard.styles';

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth={maxWidth.l}>
      <CardContainer>
        <DataCard
          onClick={() => {
            navigate(Routes.Account);
          }}
        >
          Meine Daten
        </DataCard>
        <DonationCard
          onClick={() => {
            navigate(Routes.Donation);
          }}
        >
          Team-Spende
        </DonationCard>
        <DiaryCard
          onClick={() => {
            navigate(Routes.Diary);
          }}
        >
          Radel-Tagebuch
        </DiaryCard>
        <ImageUploadCard
          onClick={() => {
            navigate(Routes.ImageUpload);
          }}
        >
          Bilder-Upload
        </ImageUploadCard>
      </CardContainer>
    </Container>
  );
};

export default Dashboard;
