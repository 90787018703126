import styled from 'styled-components';

import { maxWidth } from './Container';

interface ContainerRootProps {
  maxWidth: maxWidth;
}

export const ContainerRoot = styled.div<ContainerRootProps>`
  max-width: ${(props) => props.maxWidth};
  margin: auto;
`;
