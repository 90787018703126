import React, { useEffect, useState } from 'react';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import TeamModel from '@Api/models/TeamModel';
import UserModel from '@Api/models/UserModel';
import { useRequest } from '@Api/useRequest';

import Container, { maxWidth } from '@Components/Container/Container';
import { Form } from '@Components/Form/Form';
import Input from '@Components/Input/Input';

import { apiErrorToast, successToast } from '@Helper/toastHelper';

import { Submit } from './Account.styles';

const Account = () => {
  const [teamName, setTeamName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const { executeRequest: getUserExecuteRequest } = useRequest<UserModel>(
    getBackendRoute(ApiRoutes.getAccount),
    'GET',
    UserModel.hydrateFromJson,
    (result) => {
      setFirstName(result.firstName);
      setLastName(result.lastName);
      setEmail(result.email);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  const { executeRequest: getTeamExecuteRequest } = useRequest<TeamModel>(
    getBackendRoute(ApiRoutes.getTeam),
    'GET',
    TeamModel.hydrateFromJson,
    (result) => {
      setTeamName(result.name);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  useEffect(() => {
    getUserExecuteRequest();
    getTeamExecuteRequest();
  }, []);

  const {
    loading: updateTeamNameLoading,
    executeRequest: updateTeamNameExecuteRequest,
    validationError: updateTeamNameValidationError,
  } = useRequest<DefaultResponseModel>(
    getBackendRoute(ApiRoutes.updateTeamName),
    'POST',
    DefaultResponseModel.hydrateFromJson,
    (result) => {
      successToast(result.message);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  const {
    loading: updateUserNameLoading,
    executeRequest: updateUserNameExecuteRequest,
    validationError: updateUserNameValidationError,
  } = useRequest<DefaultResponseModel>(
    getBackendRoute(ApiRoutes.updateUserName),
    'POST',
    DefaultResponseModel.hydrateFromJson,
    (result) => {
      successToast(result.message);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  const {
    loading: updateUserEmailLoading,
    executeRequest: updateUserEmailExecuteRequest,
    validationError: updateUserEmailValidationError,
  } = useRequest<DefaultResponseModel>(
    getBackendRoute(ApiRoutes.updateUserEmail),
    'POST',
    DefaultResponseModel.hydrateFromJson,
    (result) => {
      successToast(result.message);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  const {
    loading: updateUserPasswordLoading,
    executeRequest: updateUserPasswordExecuteRequest,
    validationError: updateUserPasswordValidationError,
  } = useRequest<DefaultResponseModel>(
    getBackendRoute(ApiRoutes.updateUserPassword),
    'POST',
    DefaultResponseModel.hydrateFromJson,
    (result) => {
      successToast(result.message);
      setCurrentPassword('');
      setNewPassword('');
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  const handleTeamNameChange = async () => {
    const data = {
      teamName,
    };

    await updateTeamNameExecuteRequest(data);
  };

  const handleCaptainNameChange = async () => {
    const data = {
      firstName,
      lastName,
    };

    await updateUserNameExecuteRequest(data);
  };

  const handleEmailChange = async () => {
    const data = {
      email: email,
    };

    await updateUserEmailExecuteRequest(data);
  };

  const handlePasswordChange = async () => {
    const data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    await updateUserPasswordExecuteRequest(data);
  };

  return (
    <Container maxWidth={maxWidth.s}>
      <h2>Meine Daten:</h2>
      <Form onSubmit={() => handleTeamNameChange()}>
        <Input
          type="text"
          value={teamName}
          onChange={(teamName) => setTeamName(teamName)}
          label="Teamname"
          placeholder="Gib hier den Namen deines Teams ein"
          error={updateTeamNameValidationError?.getForField('teamName') ?? []}
          required={true}
        />
        <Submit type="submit" loading={updateTeamNameLoading}>
          Teamnamen speichern
        </Submit>
      </Form>
      <Form onSubmit={() => handleCaptainNameChange()}>
        <Input
          type="text"
          value={firstName}
          onChange={(firstName) => setFirstName(firstName)}
          label="Team-Captain Vorname"
          placeholder="Gib hier deinen Vornamen ein"
          error={updateUserNameValidationError?.getForField('firstName') ?? []}
          required={true}
        />
        <Input
          type="text"
          value={lastName}
          onChange={(lastName) => setLastName(lastName)}
          label="Team-Captain Nachname"
          placeholder="Gib hier deinen Nachnamen ein"
          error={updateUserNameValidationError?.getForField('lastName') ?? []}
          required={true}
        />
        <Submit type="submit" loading={updateUserNameLoading}>
          Namen speichern
        </Submit>
      </Form>
      <Form onSubmit={() => handleEmailChange()}>
        <Input
          type="email"
          value={email}
          onChange={(email) => setEmail(email)}
          label="E-Mail"
          placeholder="Gib hier deine Mailadresse ein"
          error={updateUserEmailValidationError?.getForField('email') ?? []}
          required={true}
        />
        <Submit type="submit" loading={updateUserEmailLoading}>
          E-Mail ändern
        </Submit>
      </Form>
      <Form onSubmit={() => handlePasswordChange()}>
        <Input
          type="password"
          value={currentPassword}
          onChange={(currentPassword) => setCurrentPassword(currentPassword)}
          label="Aktuelles Passwort"
          placeholder="Gib hier dein aktuelles Passwort ein"
          error={
            updateUserPasswordValidationError?.getForField('currentPassword') ??
            []
          }
          required={true}
        />
        <Input
          type="password"
          value={newPassword}
          onChange={(newPassword) => setNewPassword(newPassword)}
          label="Neues Passwort"
          placeholder="Gib hier dein neues Passwort ein"
          error={
            updateUserPasswordValidationError?.getForField('newPassword') ?? []
          }
          required={true}
        />
        <Submit type="submit" loading={updateUserPasswordLoading}>
          Passwort ändern
        </Submit>
      </Form>
    </Container>
  );
};

export default Account;
