import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import {
  AcceptButton,
  ButtonGroup,
  ContentWindow,
  DeclineButton,
  Root,
} from './AlertModal.styles';

interface Props extends PropsWithClassName {
  onAccept: () => void;
  onDecline: () => void;
  text: string;
}

const AlertModal = (props: Props): React.ReactElement => {
  const { className, onAccept, onDecline, text } = props;

  const handleAccept = () => {
    if (!onAccept) {
      return;
    }
    onAccept();
  };

  const handleDecline = () => {
    if (!onDecline) {
      return;
    }

    onDecline();
  };

  return (
    <Root className={className} data-testid={props.dataTestId}>
      <ContentWindow minWidth={300} minHeight={100}>
        <p>{text}</p>
        <ButtonGroup>
          <DeclineButton onClick={handleDecline} />
          <AcceptButton onClick={handleAccept} />
        </ButtonGroup>
      </ContentWindow>
    </Root>
  );
};

export default AlertModal;
