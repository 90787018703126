import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { Root } from './Form.styles';

interface Props extends PropsWithClassName {
  onSubmit: () => void;
  children: React.ReactElement[];
}

const Form = (props: Props): React.ReactElement => {
  const { onSubmit, children } = props;
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Root
      className={props.className}
      data-testid={props.dataTestId}
      onSubmit={(event) => handleSubmit(event)}
    >
      {children}
    </Root>
  );
};

export { Form };
