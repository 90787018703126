export interface ValidationMessage {
  property: string;
  message: string;
  key: string;
}

export default class ValidationErrorModel {
  public code: number;
  public data: Array<ValidationMessage>;
  public message: string;

  constructor(
    code: number,
    data: Array<{ property: any; message: any }>,
    message: string
  ) {
    this.code = code;
    this.data = data.map((violation, index) => {
      return {
        property: violation.property,
        message: violation.message,
        key: `${violation.property}-${index}`,
      };
    });
    this.message = message;
  }

  public getForField(field: string): ValidationMessage[] {
    return this.data.filter((violation) => {
      return violation.property === field;
    });
  }

  public hasError(field: string): boolean {
    return this.getForField(field).length > 0;
  }
}
