import React, { useEffect, useState } from 'react';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import TeamModel from '@Api/models/TeamModel';
import { useRequest } from '@Api/useRequest';

import { useGlobalContext } from '@Context/GlobalContext';

import Container, { maxWidth } from '@Components/Container/Container';
import { DonationFaq } from '@Components/DonationFaq/DonationFaq';
import { Twingle } from '@Components/Twingle/Twingle';

import { apiErrorToast } from '@Helper/toastHelper';

import { Text } from './Donation.styles';

const Donation = () => {
  const { loggedIn } = useGlobalContext();

  const [teamName, setTeamName] = useState<string>('');

  const { executeRequest: getTeamExecuteRequest } = useRequest<TeamModel>(
    getBackendRoute(ApiRoutes.getTeam),
    'GET',
    TeamModel.hydrateFromJson,
    (result) => {
      setTeamName(result.name);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  useEffect(() => {
    if (loggedIn) {
      getTeamExecuteRequest();
    }
  }, [loggedIn]);

  const renderTwingle = () => {
    if (!loggedIn) {
      return <Twingle purpose="radeln 2023" />;
    }

    if (teamName === '') {
      return <></>;
    }

    return <Twingle purpose={`radeln 2023 ${teamName}`} />;
  };

  return (
    <Container maxWidth={maxWidth.l}>
      <h2>Unsere Team-Spende zur Radel-Aktion</h2>
      {renderTwingle()}
      <Text>
        <p>
          Zum Spenden klickt einfach den gewünschten Beitrag an und gebt bitte
          unbedingt den Spendenzweck: “radeln 2023” und Teamname an. Nur so
          können wir eure Spenden dem Radel- Wettbewerb und eurem Team zuordnen.
        </p>
        <p>
          Wenn ihr möchtet, könnt ihr eure Spende auch gerne direkt auf unser
          Spenden-Konto überweisen:
        </p>
        <p>
          Spendenkonto: wünschdirwas e.V.
          <br />
          IBAN: DE77 3705 0198 1951 9519 51
        </p>
        <p>
          Spenden/Zuwendungen an wünschdirwas e.V. sind steuerlich abzugsfähig.{' '}
        </p>
        <DonationFaq />
      </Text>
    </Container>
  );
};

export default Donation;
