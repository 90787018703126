export enum Routes {
  Account = '/account',
  Dashboard = '/dashboard',
  Donation = '/spenden',
  Login = '/login',
  RegisterComplete = '/register/complete',
  Team = '/team',
  Editor = '/editor',
  Diary = '/diary',
  ImageUpload = '/images',
  PasswordReset = '/password/reset',
  PasswordRecover = '/password/recover',
}
