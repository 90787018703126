import styled from 'styled-components';

import Button from '@Components/Button/Button';
import { LoadingSpinner } from '@Components/LoadingSpinner/LoadingSpinner';

export const Root = styled.div`
  border: 1px solid black;
  padding: 20px;

  @media (max-width: 600px) {
    border: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledButton = styled(Button)`
  margin: 0.5rem;
`;

export const LoadingBox = styled.div`
  border: 1px solid #2196f3;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  margin-bottom: 2rem;
`;

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  border: 6px solid rgba(33, 150, 243, 0.5);
  border-top-color: #2196f3;
  height: 2em;
  width: 2em;
  margin: 0.5rem;
`;

export const ArticleLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ArticleLink = styled.a`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 50%;
  height: 2.813rem;

  background: #04AA6D;
  border: none;
  border-radius: 0.25rem;

  font-family: 'Bree Serif', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #ffffff;
  text-decoration: none;
  
  margin-top: 2rem;
`
