import styled from 'styled-components';

export const Root = styled.div`
  margin-top: 7rem;
`;

export const Divider = styled.div`
  border-top: 4px #1d4596 dotted;
  width: 100%;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledLink = styled.a`
  margin: 1rem;
  color: #000000;
  text-decoration: none;
`;
