export default class DefaultResponseModel {
  public code: number;
  public message: string;
  public data: Array<string>;

  constructor(code: number, message: string, data: Array<string>) {
    this.code = code;
    this.message = message;
    this.data = data;
  }

  public static hydrateFromJson(data: any): DefaultResponseModel {
    return new DefaultResponseModel(data.code, data.message, data.data);
  }
}
