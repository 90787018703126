import styled from 'styled-components/macro';

interface DivProps {
  minWidth: number;
  minHeight: number;
}

export const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  z-index: 10000;
`;

export const ContentWindow = styled.div<DivProps>`
  background-color: #ffffff;
  border-radius: 3px;
  width: 20%;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-width: ${(props) => `${props.minWidth}px`};
  min-height: ${(props) => `${props.minHeight}px`};

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;

export const Content = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;

  @media (max-width: 600px) {
    padding: 0.5rem;
  }
`;

export const Headline = styled.h2`
  padding-left: 2rem;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: end;
  padding-top: 1rem;
  padding-right: 1rem;
`;
