import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PropsWithClassName } from '@Helper/PropsWithClassName';
import { Routes } from '@Helper/Routes';

import { Root, StyledImage } from './WdwLogo.styles';

interface Props extends PropsWithClassName {}

const WdwLogo = (props: Props) => {
  const navigate = useNavigate();

  return (
    <Root
      className={props.className}
      onClick={() => navigate(Routes.Dashboard)}
      data-testid={props.dataTestId}
    >
      <StyledImage src="/images/wdw-logo.png" alt="wünsch dir was logo" />
    </Root>
  );
};

export default WdwLogo;
