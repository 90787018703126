import styled from 'styled-components';

export const Root = styled.form``;

export const TwingleIFrame = styled.iframe`
  width: 100%;
  border: none;
  overflow: hidden;
  height: 650px;
  position: relative;

  @media (max-width: 655px) {
    height: 630px;
  }
`;
