import { IconUpload } from '@tabler/icons';
import React from 'react';
import Dropzone from 'react-dropzone';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { DropzoneContainer, Root } from './InputDropzone.styles';

interface Props extends PropsWithClassName {
  onDrop: (files: File[]) => void;
  text?: string;
}

const InputDropzone = (props: Props): React.ReactElement => {
  const { onDrop, text } = props;

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      <Dropzone
        onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
        accept={{ 'image/png': ['.png', '.jpg', '.jpeg'] }}
      >
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer {...getRootProps()}>
            <IconUpload size={60} stroke={1} />
            <input {...getInputProps()} />
            <p>{text}</p>
          </DropzoneContainer>
        )}
      </Dropzone>
    </Root>
  );
};
export default InputDropzone;
