import React, { useEffect, useState } from 'react';

import ArticleModel from '@Api/models/ArticleModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';

import ArticleEditBox from '@Components/ArticleEditBox/ArticleEditBox';
import Modal from '@Components/Modal/Modal';

import { base64ToFile } from '@Helper/image';
import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { Root } from './ArticleEditModal.styles';

interface Props extends PropsWithClassName {
  article: ArticleModel;
  onClose: () => void;
  update: (
    id: string,
    title: string,
    content: string,
    status: 'draft' | 'published',
    files: File[]
  ) => void;
  updateValidationError?: ValidationErrorModel | null;
  loading?: boolean;
}

const ArticleEditModal = (props: Props): React.ReactElement => {
  const { article, onClose, update, updateValidationError, loading } = props;

  const [initFiles, setInitFiles] = useState<File[]>([]);

  const getBase64Files = async () => {
    if (!article.images) {
      return;
    }
    const imageFiles = await Promise.all(
      article.images.map(async (image) => {
        return await base64ToFile(image.file);
      })
    );
    setInitFiles(imageFiles);
  };

  useEffect(() => {
    getBase64Files();
  }, [article.images]);

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      <Modal onClose={onClose}>
        <ArticleEditBox
          update={(title, content, status, files) =>
            update(article.id, title, content, status, files)
          }
          initTitle={article.title}
          initContent={article.content}
          initFiles={initFiles}
          isPublished={article.status === 'published'}
          articleLink={article.articleLink}
          updateValidationError={updateValidationError}
          loading={loading}
        />
      </Modal>
    </Root>
  );
};

export default ArticleEditModal;
