import React, { useState } from 'react';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import { useRequest } from '@Api/useRequest';

import Button from '@Components/Button/Button';
import Container, { maxWidth } from '@Components/Container/Container';
import { Form } from '@Components/Form/Form';
import Input from '@Components/Input/Input';

import { apiErrorToast, successToast } from '@Helper/toastHelper';

const PasswordRecover = () => {
  const [email, setEmail] = useState<string>('');

  const { loading, executeRequest, validationError } =
    useRequest<DefaultResponseModel>(
      getBackendRoute(ApiRoutes.passwordRecover),
      'POST',
      DefaultResponseModel.hydrateFromJson,
      (result) => {
        successToast(result.message);
      },
      (error) => {
        apiErrorToast(error);
      }
    );

  const recoverPassword = async () => {
    const data = {
      email: email,
    };

    await executeRequest(data);
  };

  return (
    <Container maxWidth={maxWidth.s}>
      <h3>Hier kannst du dein Passwort zurücksetzen:</h3>
      <Form onSubmit={() => recoverPassword()}>
        <Input
          type="email"
          value={email}
          onChange={(email) => setEmail(email)}
          label="E-Mail"
          placeholder="Gib hier deine Mailadresse ein"
          error={validationError?.getForField('email') ?? []}
          required={true}
        />
        <Button loading={loading} type="submit">
          Passwort zurücksetzen
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordRecover;
