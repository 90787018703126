import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import LoginModel from '@Api/models/LoginModel';
import { useRequest } from '@Api/useRequest';

import { useGlobalContext } from '@Context/GlobalContext';

import Button from '@Components/Button/Button';
import Container, { maxWidth } from '@Components/Container/Container';
import { Form } from '@Components/Form/Form';
import Input from '@Components/Input/Input';

import { Routes } from '@Helper/Routes';
import { apiErrorToast } from '@Helper/toastHelper';

import { StyledLink } from './Login.styles';

const Login = () => {
  const { login } = useGlobalContext();

  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { loading, executeRequest, validationError } = useRequest<LoginModel>(
    getBackendRoute(ApiRoutes.login),
    'POST',
    LoginModel.hydrateFromJson,
    (result) => {
      login(result.data.token);
      navigate(Routes.Dashboard);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  const handleLogin = async () => {
    const data = {
      username: email,
      password: password,
    };

    await executeRequest(data);
  };

  return (
    <Container maxWidth={maxWidth.s}>
      <p>Willkommen zurück zum wünschdirwas Radel-Profil! </p>
      <h3>Hier kannst du dich einloggen:</h3>
      <Form onSubmit={() => handleLogin()}>
        <Input
          type="email"
          value={email}
          onChange={(email) => setEmail(email)}
          label="E-Mail"
          placeholder="Gib hier deine Mailadresse ein"
          error={validationError?.getForField('username') ?? []}
          required={true}
        />
        <Input
          type="password"
          value={password}
          onChange={(password) => setPassword(password)}
          label="Passwort"
          placeholder="Gib hier dein Passwort ein"
          error={validationError?.getForField('password') ?? []}
          required={true}
        />
        <Button type="submit" loading={loading}>
          Login
        </Button>
        <StyledLink onClick={() => navigate(Routes.PasswordRecover)}>
          Passwort vergessen?
        </StyledLink>
        <div>
          Noch kein Profil? Informiere dich{' '}
          <a href="https://wuenschdirwas.de/spende/radeln-fuer-wuenschdirwas/">
            hier
          </a>{' '}
          und melde dich zur Aktion an.
        </div>
      </Form>
    </Container>
  );
};

export default Login;
