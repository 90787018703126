import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const DataCard = styled.div`
  cursor: pointer;
  height: 12rem;
  width: 22rem;
  margin: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 36px;
  color: #fff;
  background: #ffc000;
`;

export const DonationCard = styled.div`
  cursor: pointer;
  height: 12rem;
  width: 22rem;
  margin: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 36px;
  color: #fff;
  background: #9f216a;
`;

export const DiaryCard = styled.div`
  cursor: pointer;
  height: 12rem;
  width: 22rem;
  margin: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 36px;
  color: #fff;
  background: #ef7921;
`;

export const ImageUploadCard = styled.div`
  cursor: pointer;
  height: 12rem;
  width: 22rem;
  margin: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 36px;
  color: #fff;
  background: #00a151;
`;
