import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useGlobalContext } from '@Context/GlobalContext';

import Account from '@Pages/Account/Account';
import Dashboard from '@Pages/Dashboard/Dashboard';
import Diary from '@Pages/Diary/Diary';
import Donation from '@Pages/Donation/Donation';
import EmailUpdateComplete from '@Pages/EmailUpdateComplete/EmailUpdateComplete';
import ImageUpload from '@Pages/ImageUpload/ImageUpload';
import Login from '@Pages/Login/Login';
import NotFound from '@Pages/NotFound/NotFound';
import PasswordRecover from '@Pages/PasswordRecover/PasswordRecover';
import PasswordReset from '@Pages/PasswordReset/PasswordReset';
import RegisterComplete from '@Pages/RegisterComplete/RegisterComplete';

import { Routes as AppRoutes } from '@Helper/Routes';

import App from '../App';

const Routing = () => {
  const { loggedIn } = useGlobalContext();

  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={
            loggedIn ? (
              <Navigate to={AppRoutes.Dashboard} />
            ) : (
              <Navigate to={AppRoutes.Login} />
            )
          }
        />
        <Route path="register/complete" element={<RegisterComplete />} />
        <Route path="email/update/complete" element={<EmailUpdateComplete />} />
        <Route path="spenden" element={<Donation />} />
        <Route
          path="password/recover"
          element={
            loggedIn ? (
              <Navigate to={AppRoutes.Dashboard} />
            ) : (
              <PasswordRecover />
            )
          }
        />
        <Route path="password/reset" element={<PasswordReset />} />
        <Route
          path="login"
          element={loggedIn ? <Navigate to={AppRoutes.Dashboard} /> : <Login />}
        />
        <Route
          path="dashboard"
          element={
            !loggedIn ? <Navigate to={AppRoutes.Login} /> : <Dashboard />
          }
        />
        <Route
          path="account"
          element={!loggedIn ? <Navigate to={AppRoutes.Login} /> : <Account />}
        />
        <Route
          path="images"
          element={
            !loggedIn ? <Navigate to={AppRoutes.Login} /> : <ImageUpload />
          }
        />
        <Route
          path="diary"
          element={!loggedIn ? <Navigate to={AppRoutes.Login} /> : <Diary />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Routing;
