import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { Root } from './LoadingSpinner.styles';

interface Props extends PropsWithClassName {}

const LoadingSpinner = (props: Props): React.ReactElement => {
  return (
    <Root className={props.className} data-testid={props.dataTestId}></Root>
  );
};

export { LoadingSpinner };
