import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { Root, TwingleIFrame } from './Twingle.styles';

interface Props extends PropsWithClassName {
  purpose: string;
}

const Twingle = (props: Props): React.ReactElement => {
  const { purpose } = props;

  const baseUrl =
    'https://spenden.twingle.de/wunschdirwas-e-v/direktspende/tw5f7c2755d06e7/widget';
  const randomId = '_' + Math.random().toString(36).substr(2, 9);

  const formattedPurpose = purpose.replace(' ', '%20');

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      <TwingleIFrame
        id={randomId}
        loading="lazy"
        src={`${baseUrl}/${randomId}?tw_amount=100&tw_target=${formattedPurpose}`}
      />
    </Root>
  );
};

export { Twingle };
