import { IconCheck, IconX } from '@tabler/icons';
import styled from 'styled-components/macro';

interface DivProps {
  minWidth: number;
  minHeight: number;
}

export const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  z-index: 10010;
`;

export const ContentWindow = styled.div<DivProps>`
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 3px;
  width: 20%;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  min-width: ${(props) => `${props.minWidth}px`};
  min-height: ${(props) => `${props.minHeight}px`};

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    min-width: 100%;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
`;

export const DeclineButton = styled(IconX)`
  color: #ffffff;
  background-color: gray;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
`;

export const AcceptButton = styled(IconCheck)`
  color: #ffffff;
  background-color: green;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
`;
