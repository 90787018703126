export default class LoginModel {
  public code: number;
  public data: any;
  public message: string;
  public readonly isSuccessful: boolean;

  constructor(code: number, data: any, message: string) {
    this.code = code;
    this.data = data;
    this.message = message;
    this.isSuccessful = code === 200;
  }

  public static hydrateFromJson(data: any) {
    return new LoginModel(data.code, data.data, data.message);
  }
}
