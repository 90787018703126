import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { ContainerRoot } from './Container.styles';

export enum maxWidth {
  's' = '400px',
  'm' = '600px',
  'l' = '800px',
}

interface Props extends PropsWithClassName {
  children: React.ReactNode;
  maxWidth: maxWidth;
}

const Container = (props: Props): React.ReactElement => {
  const { children, maxWidth } = props;

  return (
    <ContainerRoot
      className={props.className}
      data-testid={props.dataTestId}
      maxWidth={maxWidth}
    >
      {children}
    </ContainerRoot>
  );
};

export default Container;
