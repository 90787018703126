export default class TeamModel {
  public id: string;
  public name: string;
  public createdAt?: string;
  public updatedAt?: string;
  public members?: string[];

  constructor(
    id: string,
    name: string,
    createdAt?: string,
    updatedAt?: string,
    members?: string[]
  ) {
    this.id = id;
    this.name = name;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.members = members;
  }

  public static hydrateFromJson(data: any) {
    return new TeamModel(
      data.id,
      data.name,
      data.createdAt,
      data.updatedAt,
      data.members
    );
  }
}
