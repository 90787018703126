import React from 'react';

import { LoadingSpinner } from '@Components//LoadingSpinner/LoadingSpinner';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { ButtonRoot } from './Button.styles';

interface Props extends PropsWithClassName {
  children: string;
  onClick?: () => void;
  loading?: boolean;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'success' | 'error' | 'secondary';
}

const Button = (props: Props): React.ReactElement => {
  const { children, onClick, loading, type, variant } = props;

  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ButtonRoot
      className={props.className}
      data-testid={props.dataTestId}
      type={type ?? 'button'}
      onClick={() => handleClick()}
      variant={variant ?? 'primary'}
    >
      {children}
      {loading && <LoadingSpinner />}
    </ButtonRoot>
  );
};

export default Button;
