import { IconBook } from '@tabler/icons';
import React from 'react';

import ArticleModel from '@Api/models/ArticleModel';

import Badge from '@Components/Badge/Badge';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { Article, Root } from './ArticleListBox.styles';

interface Props extends PropsWithClassName {
  articles: ArticleModel[];
  onClick: (id: string) => void;
}

const ArticleListBox = (props: Props): React.ReactElement => {
  const { articles, onClick } = props;

  const renderBadge = (status: string, linkText?: string) => {
    switch (status) {
      case 'draft':
        return <Badge variant="warning">Entwurf</Badge>;
      case 'published':
        if (linkText) {
          return <Badge variant="success">öffentlich</Badge>;
        }
        return <Badge variant="secondary">geteilt</Badge>;
      default:
        return <></>;
    }
  };

  const renderArticle = (article: ArticleModel) => {
    return (
      <Article key={article.id} onClick={() => onClick(article.id)}>
        <IconBook size={50} />
        <div>
          {article.title.length > 50
            ? article.title.substring(0, 47) + '...'
            : article.title}
        </div>
        {renderBadge(article.status, article.articleLink)}
      </Article>
    );
  };

  const renderArticles = () => {
    return articles.map((article) => renderArticle(article));
  };

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      {renderArticles()}
    </Root>
  );
};

export default ArticleListBox;
