import styled from 'styled-components';

interface AlertRootProps {
  type: 'error' | 'success' | 'warning' | 'info';
}

const handleBackgroundColor = (type: string) => {
  switch (type) {
    case 'error':
      return '#f44336';
    case 'success':
      return '#04AA6D';
    case 'warning':
      return '#ff9800';
    case 'info':
    default:
      return '#2196f3';
  }
};

export const Icon = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`;

export const AlertRoot = styled.div<AlertRootProps>`
  padding: 20px;
  background: ${(props) => handleBackgroundColor(props.type)};
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
`;
