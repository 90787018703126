import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { Root } from './Link.styles';

interface Props extends PropsWithClassName {
  children: string;
  onClick?: () => void;
}

const Link = (props: Props): React.ReactElement => {
  const { onClick, children } = props;

  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Root
      className={props.className}
      data-testid={props.dataTestId}
      onClick={() => handleClick()}
    >
      {children}
    </Root>
  );
};

export default Link;
