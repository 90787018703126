import React from 'react';

import { ValidationMessage } from '@Api/models/ValidationErrorModel';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import {
  Error,
  Label,
  LengthInfo,
  Root,
  StyledTextArea,
} from './InputArea.styles';

interface Props extends PropsWithClassName {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  label?: string;
  placeholder?: string;
  error?: ValidationMessage[];
  max?: number;
  disabled?: boolean;
}

const InputArea = (props: Props): React.ReactElement => {
  const {
    value,
    onChange,
    label,
    placeholder,
    error,
    required,
    max,
    disabled,
  } = props;

  const isError = error !== undefined && error.length > 0;
  const maxLength = max ?? 2000;

  return (
    <Root className={props.className} data-testid={props.dataTestId}>
      {label && <Label isError={isError}>{label}</Label>}
      <StyledTextArea
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        isError={isError}
        required={required ?? false}
        maxLength={maxLength}
        disabled={disabled ?? false}
      />
      <LengthInfo>{maxLength - value.length} Zeichen verbleibend</LengthInfo>
      {error &&
        error.map((message: ValidationMessage) => (
          <Error key={message.key}>{message.message}</Error>
        ))}
    </Root>
  );
};

export default InputArea;
