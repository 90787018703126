import React, { useEffect, useState } from 'react';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import UserModel from '@Api/models/UserModel';
import { useRequest } from '@Api/useRequest';
import { useWordpressMediaGetRequest } from '@Api/wordpress/useWordpressMediaGetRequest';

import Alert from '@Components/Alert/Alert';
import AlertModal from '@Components/AlertModal/AlertModal';
import Button from '@Components/Button/Button';
import Container, { maxWidth } from '@Components/Container/Container';
import ImageBox from '@Components/ImageBox/ImageBox';
import InputDropzone from '@Components/InputDropzone/InputDropzone';
import Modal from '@Components/Modal/Modal';

import { toBase64 } from '@Helper/image';
import { apiErrorToast, errorToast } from '@Helper/toastHelper';

import { LoadingBox, StyledLoadingSpinner } from './ImageUpload.styles';

const ImageUpload = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [executeModalIsOpen, setExecuteModalIsOpen] = useState<boolean>(false);
  const [fileLengthError, setFileLengthError] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [userId, setUserId] = useState<string>('');
  const [uploadedImageUrls, setUploadedImageUrls] = useState<string[]>([]);

  const { executeRequest: getUserExecuteRequest } = useRequest<UserModel>(
    getBackendRoute(ApiRoutes.getAccount),
    'GET',
    UserModel.hydrateFromJson,
    (result) => {
      setUserId(result.id);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  useEffect(() => {
    getUserExecuteRequest();
  }, []);

  const { executeRequest: getMediaExecuteRequest } =
    useWordpressMediaGetRequest(
      (results) => {
        const imageUrls = results.map((result) => result.link);
        setUploadedImageUrls(imageUrls);
      },
      (error) => {
        apiErrorToast(error);
      },
      (error) => {
        errorToast(error.message);
      }
    );

  useEffect(() => {
    if (userId !== '') {
      getMediaExecuteRequest(userId);
    }
  }, [userId]);

  const {
    executeRequest: uploadImagesExecuteRequest,
    loading: uploadImagesLoading,
  } = useRequest<DefaultResponseModel>(
    getBackendRoute(ApiRoutes.uploadImages),
    'POST',
    DefaultResponseModel.hydrateFromJson,
    () => {
      setShowSuccessAlert(true);
      if (userId !== '') {
        getMediaExecuteRequest(userId);
      }
    },
    (error) => {
      setErrorAlert(error.body.message ? error.body.message : error.message);
    },
    (error) => {
      setErrorAlert(error.message);
    }
  );

  const handleCreate = async (files: File[]) => {
    const transformedFiles = await Promise.all(
      files.map(async (file) => {
        return await toBase64(file);
      })
    );

    const data = {
      files: transformedFiles,
    };

    await uploadImagesExecuteRequest(data);
  };

  useEffect(() => {
    if (files.length < 10) {
      setFileLengthError(false);
    }

    if (files.length <= 10) {
      return;
    }

    setFiles(files.filter((file, index) => index < 10));
    setFileLengthError(true);
  }, [files]);

  const handleDrop = (files: File[]) => {
    setFiles(files);
    setModalIsOpen(true);
  };

  const handleDelete = (deleteIndex: number) => {
    setFiles(files.filter((file, index) => index !== deleteIndex));
  };

  const renderExecuteModal = () => {
    return (
      <AlertModal
        onAccept={() => {
          handleCreate(files);
          setErrorAlert(null);
          setShowSuccessAlert(false);
          setExecuteModalIsOpen(false);
          setModalIsOpen(false);
        }}
        onDecline={() => {
          setExecuteModalIsOpen(false);
        }}
        text="Bilder mit wünschdirwas teilen?"
      />
    );
  };

  const renderModal = () => {
    return (
      <Modal
        onClose={() => setModalIsOpen(false)}
        headline="Deine Bild-Auswahl"
      >
        <Alert type="info">
          Bitte bedenke, dass du uns hiermit die Erlaubnis gibst, die Bilder für
          unserer Kommunikation zu nutzen. Du kannst deine Auswahl an dieser
          Stelle auch noch einmal bearbeiten. Der maximale Datenumfang pro
          Upload-Vorgang darf 10 Bilder nicht überschreiten.
        </Alert>
        {fileLengthError && (
          <Alert type="error">
            Es ist leider nicht möglich mehr als 10 Bilder hochzuladen
          </Alert>
        )}
        <ImageBox
          files={files}
          onDelete={handleDelete}
          emptyMessage="Lade Bilder hoch, um sie hier noch einmal zu überprüfen"
        />
        {files.length < 10 && (
          <InputDropzone
            onDrop={(newFiles) => setFiles([...files, ...newFiles])}
            text="ziehe hier deine Bilder rein oder klicke hier um weitere Bilder
              hochzuladen"
          />
        )}
        {executeModalIsOpen && renderExecuteModal()}
        <Button onClick={() => setExecuteModalIsOpen(true)} variant="success">
          Bilder einreichen
        </Button>
      </Modal>
    );
  };

  const renderDropzoneContent = () => {
    if (uploadImagesLoading) {
      return (
        <LoadingBox>
          <StyledLoadingSpinner />
          <div>Bilder werden hochgeladen...</div>
        </LoadingBox>
      );
    }

    return (
      <InputDropzone
        onDrop={(files) => handleDrop(files)}
        text="ziehe hier deine Bilder rein oder klicke hier um Bilder
              hochzuladen"
      />
    );
  };

  return (
    <Container maxWidth={maxWidth.m}>
      <h2>Deine Bilder zur Radel-Aktion</h2>
      <Alert type="info">
        Hier kannst du eure Radel-Bilder hochladen und uns zur Verfügung
        stellen. Wir würden die eingereichten Bilder für die öffentliche
        Kommunikation auf unserer Webseite und sozialen Medien nutzen, um so auf
        eure Leistung und Teilnahme an der Radel-Aktion für einen guten Zweck
        aufmerksam zu machen.
      </Alert>
      {showSuccessAlert && (
        <Alert type="success">Bilder wurden erfolgreich hochgeladen.</Alert>
      )}
      {errorAlert && <Alert type="error">{errorAlert}</Alert>}
      <h3>Neue Bilder hochladen:</h3>
      {renderDropzoneContent()}
      {modalIsOpen && renderModal()}
      <h3>Deine eingereichten Bilder:</h3>
      <ImageBox
        images={uploadedImageUrls}
        emptyMessage="Hier siehst du deine bereits eingereichten Bilder"
      />
    </Container>
  );
};

export default ImageUpload;
