import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Container, { maxWidth } from '@Components/Container/Container';
import Footer from '@Components/Footer/Footer';
import Header from '@Components/Header/Header';

function App() {
  return (
    <Container maxWidth={maxWidth.l} className="App">
      <Header />
      <Outlet />
      <ToastContainer />
      <Footer />
    </Container>
  );
}

export default App;
