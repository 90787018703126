import React, { useEffect, useState } from 'react';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import ArticleModel from '@Api/models/ArticleModel';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import { useRequest } from '@Api/useRequest';

import Alert from '@Components/Alert/Alert';
import ArticleEditBox from '@Components/ArticleEditBox/ArticleEditBox';
import ArticleEditModal from '@Components/ArticleEditModal/ArticleEditModal';
import ArticleListBox from '@Components/ArticleListBox/ArticleListBox';
import Container, { maxWidth } from '@Components/Container/Container';

import { toBase64 } from '@Helper/image';
import { apiErrorToast, successToast } from '@Helper/toastHelper';

const Diary = () => {
  const [articles, setArticles] = useState<ArticleModel[]>([]);
  const [currentArticle, setCurrentArticle] = useState<ArticleModel | null>(
    null
  );

  const { executeRequest: listArticlesExecuteRequest } = useRequest<
    ArticleModel[]
  >(
    getBackendRoute(ApiRoutes.listArticles),
    'GET',
    ArticleModel.hydrateMultipleFromJson,
    (result) => {
      setArticles(result);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  useEffect(() => {
    listArticlesExecuteRequest();
  }, []);

  const {
    executeRequest: createArticleExecuteRequest,
    validationError: createArticleValidationError,
    loading: createArticleLoading,
  } = useRequest<DefaultResponseModel>(
    getBackendRoute(ApiRoutes.createArticle),
    'POST',
    DefaultResponseModel.hydrateFromJson,
    (result) => {
      successToast(result.message);
      listArticlesExecuteRequest();
    },
    (error) => {
      apiErrorToast(error);
      listArticlesExecuteRequest();
    }
  );

  const handleCreate = async (
    title: string,
    content: string,
    status: 'draft' | 'published',
    files: File[]
  ) => {
    const transformedFiles = await Promise.all(
      files.map(async (file) => {
        return await toBase64(file);
      })
    );

    const data = {
      title,
      content,
      status,
      files: transformedFiles,
    };

    await createArticleExecuteRequest(data);
  };

  const {
    executeRequest: updateArticleExecuteRequest,
    validationError: updateArticleValidationError,
    loading: updateArticleLoading,
  } = useRequest<DefaultResponseModel>(
    getBackendRoute(ApiRoutes.updateArticle),
    'POST',
    DefaultResponseModel.hydrateFromJson,
    (result) => {
      setCurrentArticle(null);
      successToast(result.message);
      listArticlesExecuteRequest();
    },
    (error) => {
      apiErrorToast(error);
      listArticlesExecuteRequest();
    }
  );

  const handleUpdate = async (
    id: string,
    title: string,
    content: string,
    status: 'draft' | 'published',
    files: File[]
  ) => {
    const transformedFiles = await Promise.all(
      files.map(async (file) => {
        return await toBase64(file);
      })
    );

    const data = {
      id,
      title,
      content,
      status,
      files: transformedFiles,
    };

    await updateArticleExecuteRequest(data);
  };

  const { executeRequest: getArticleExecuteRequest } = useRequest<ArticleModel>(
    getBackendRoute(ApiRoutes.getArticle),
    'GET',
    ArticleModel.hydrateFromJson,
    (result) => {
      setCurrentArticle(result);
    },
    (error) => {
      apiErrorToast(error);
    }
  );

  const handleGet = async (articleId: string) => {
    await getArticleExecuteRequest(articleId);
  };

  const renderArticleEditModal = () => {
    if (currentArticle === null) {
      return <></>;
    }

    return (
      <ArticleEditModal
        article={currentArticle}
        onClose={() => {
          setCurrentArticle(null);
        }}
        update={handleUpdate}
        loading={updateArticleLoading}
        updateValidationError={updateArticleValidationError}
      />
    );
  };

  return (
    <Container maxWidth={maxWidth.m}>
      <h2>Dein Radel-Tagebuch</h2>
      <Alert type="info">
        Hier kannst du eure Erlebnisse in Form eines Online-Radel-Tagebuchs
        festhalten und wünschdirwas zur Verfügung stellen. Die eingereichten
        Artikel könnten dann auf der Webseite unter der Rubrik “Blog” öffentlich
        eingesehen werden. Wir freuen uns auf eure Geschichten! Der maximale
        Datenumfang pro Upload-Vorgang darf 3 Bilder nicht überschreiten.
      </Alert>
      <h3>Neuen Artikel erstellen:</h3>
      <ArticleEditBox
        update={handleCreate}
        updateValidationError={createArticleValidationError}
        loading={createArticleLoading}
      />
      {renderArticleEditModal()}
      {articles.length > 0 && (
        <>
          <h3>Deine erstellten Artikel:</h3>
          <ArticleListBox
            articles={articles}
            onClick={(articleId) => handleGet(articleId)}
          />
        </>
      )}
    </Container>
  );
};

export default Diary;
