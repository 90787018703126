import styled from 'styled-components';

import { LoadingSpinner } from '@Components/LoadingSpinner/LoadingSpinner';

export const LoadingBox = styled.div`
  border: 1px solid #2196f3;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  border: 6px solid rgba(33, 150, 243, 0.5);
  border-top-color: #2196f3;
  height: 2em;
  width: 2em;
  margin: 0.5rem;
`;
