enum LocalStorageKeys {
  authorization = 'Authorization',
}

export const getLocalStorageAuthorization = (): string | null => {
  return getFromLS(LocalStorageKeys.authorization);
};

export const setLocalStorageAuthorization = (authorization: string) => {
  setToLS(LocalStorageKeys.authorization, authorization);
};

export const deleteLocalStorageAuthorization = () => {
  deleteFromLS(LocalStorageKeys.authorization);
};

const setToLS = (key: string, value: string): void => {
  assertWindowIsAvailable(key);

  window.localStorage.setItem(key, value);
};

const getFromLS = (key: string): string | null => {
  assertWindowIsAvailable(key);

  return window.localStorage.getItem(key);
};

const deleteFromLS = (key: string) => {
  assertWindowIsAvailable(key);

  window.localStorage.removeItem(key);
};

const assertWindowIsAvailable = (key: string): void => {
  // should never happen. At this point window should always be loaded
  if (typeof window === 'undefined') {
    throw new Error(
      `Unexpected Error while handling local storage with key [${key}]. 
      [window] is not accessible`
    );
  }
};
