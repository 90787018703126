import styled from 'styled-components';

import WdwLogo from '@Components//WdwLogo/WdwLogo';
import { StyledImage } from '@Components//WdwLogo/WdwLogo.styles';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const StyledWdwLogo = styled(WdwLogo)`
  ${StyledImage} {
    max-width: 10rem;
    margin: 0;
  }

  margin-bottom: 0.25rem;
`;

export const LinkContainerLeft = styled.div`
  min-width: 4rem;
`;

export const LinkContainerRight = styled.div`
  min-width: 4rem;
  text-align: end;
`;
