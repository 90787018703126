export default class UserModel {
  public id: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public createdAt?: string;
  public updatedAt?: string;
  public isActivated?: boolean;

  constructor(
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    createdAt?: string,
    updatedAt?: string,
    isActivated?: boolean
  ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.isActivated = isActivated;
  }

  public static hydrateFromJson(data: any) {
    return new UserModel(
      data.id,
      data.email,
      data.firstName,
      data.lastName,
      data.createdAt,
      data.updatedAt,
      data.isActivated
    );
  }
}
