import React from 'react';
import { Link } from 'react-router-dom';

import Container, { maxWidth } from '@Components/Container/Container';

import { Routes } from '@Helper/Routes';

const NotFound = () => {
  return (
    <Container maxWidth={maxWidth.s}>
      <h1>404 - Seite nicht gefunden</h1>
      <div>
        Klicke <Link to={Routes.Dashboard}>hier</Link> um zum Login oder dem
        Dashboard zu gelangen
      </div>
    </Container>
  );
};

export default NotFound;
