export const toBase64 = (file: File) =>
  new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result === null) {
        reject();
        return;
      }

      if (reader.result instanceof ArrayBuffer) {
        return;
      }

      resolve(reader.result.substr(reader.result.indexOf(',') + 1));
    };
    reader.onerror = (error) => reject(error);
  });

export const base64ToFile = async (base64File: string) => {
  const file = 'data:image/jpg; base64, ' + base64File;

  const mimeType = (base64File.match(/^data:([^;]+);/) || '')[1];

  const result = await fetch(file);

  const buf = await result.arrayBuffer();

  return new File([buf], 'filename.jpg', { type: mimeType });
};
