import styled from 'styled-components';

export const Root = styled.div`
  border: 1px #000 solid;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
`;

export const EmptyText = styled.p`
  text-align: center;
`;
