import { IconX } from '@tabler/icons';
import React from 'react';
import ImageGallery from 'react-image-gallery';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { CloseIcon, ContentWindow, Root } from './ImageSlider.styles';

interface Props extends PropsWithClassName {
  images: string[];
  index: number;
  onClose: () => void;
}

const ImageSlider = (props: Props): React.ReactElement => {
  const { images, onClose, index, className, dataTestId } = props;

  const handleClose = () => {
    if (!onClose) {
      return;
    }

    onClose();
  };

  const galleryImages = images.map((image) => {
    return { original: image };
  });

  return (
    <Root className={className} data-testid={dataTestId}>
      <CloseIcon>
        <IconX size={50} stroke={1} onClick={handleClose} color="#ffffff" />
      </CloseIcon>
      <ContentWindow>
        <ImageGallery
          items={galleryImages}
          startIndex={index}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </ContentWindow>
    </Root>
  );
};
export default ImageSlider;
