import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { MyGlobalContext } from './GlobalContext';
import { useGlobalContextHelper } from './useGlobalContextHelper';

interface Props extends PropsWithClassName {
  children: any;
}

const GlobalContextProvider = (props: Props) => {
  const { children } = props;
  const globalContext = useGlobalContextHelper();

  if (globalContext.isLoading) {
    return <></>;
  }

  return (
    <MyGlobalContext.Provider value={globalContext}>
      {children}
    </MyGlobalContext.Provider>
  );
};

export default GlobalContextProvider;
