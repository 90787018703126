import styled from 'styled-components';

interface BadgeRootProps {
  variant: 'primary' | 'success' | 'error' | 'warning' | 'secondary';
}

const handleBackgroundColor = (type: string) => {
  switch (type) {
    case 'error':
      return '#f44336';
    case 'warning':
      return '#EF7921';
    case 'success':
      return '#04AA6D';
    case 'secondary':
      return '#757575';
    case 'primary':
    default:
      return '#274492';
  }
};

export const BadgeRoot = styled.div<BadgeRootProps>`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 3px 7px;
  margin: 0.5rem 0;

  background: ${(props) => handleBackgroundColor(props.variant)};
  border-radius: 25px;

  font-family: 'Bree Serif', serif;
  font-style: normal;
  font-size: 12px;

  color: #ffffff;
`;
