import ImageModel from '@Api/models/ImageModel';

export default class ArticleModel {
  public id: string;
  public title: string;
  public status: string;
  public articleLink?: string;
  public images?: ImageModel[];
  public content?: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(
    id: string,
    title: string,
    status: string,
    articleLink?: string,
    images?: ImageModel[],
    content?: string,
    createdAt?: string,
    updatedAt?: string
  ) {
    this.id = id;
    this.title = title;
    this.status = status;
    this.articleLink = articleLink;
    this.images = images;
    this.content = content;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  public static hydrateFromJson(data: any) {
    let images = undefined;
    if (data.images) {
      images = data.images.map((image: any) =>
        ImageModel.hydrateFromJson(image)
      );
    }

    return new ArticleModel(
      data.id,
      data.title,
      data.status,
      data.articleLink,
      images,
      data.content,
      data.createdAt,
      data.updatedAt
    );
  }

  public static hydrateMultipleFromJson(data: any) {
    return data.data.map((singleData: any) =>
      ArticleModel.hydrateFromJson(singleData)
    );
  }
}
