import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiRoutes, getBackendRoute } from '@Api/apiRoutes';
import DefaultResponseModel from '@Api/models/DefaultResponseModel';
import { useRequest } from '@Api/useRequest';

import { useGlobalContext } from '@Context/GlobalContext';

import Button from '@Components/Button/Button';
import Container, { maxWidth } from '@Components/Container/Container';
import { Form } from '@Components/Form/Form';
import Input from '@Components/Input/Input';

import { Routes } from '@Helper/Routes';
import { apiErrorToast, errorToast, successToast } from '@Helper/toastHelper';
import { useQuery } from '@Helper/useQuery';

const PasswordReset = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { loggedIn, logout } = useGlobalContext();

  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');

  useEffect(() => {
    const token = query.get('passwordRecoveryToken');

    if (!loggedIn) {
      return;
    }

    if (token !== null) {
      logout();
      return;
    }

    navigate(Routes.Dashboard);
  }, []);

  const { loading, executeRequest, validationError } =
    useRequest<DefaultResponseModel>(
      getBackendRoute(ApiRoutes.passwordReset),
      'POST',
      DefaultResponseModel.hydrateFromJson,
      (result) => {
        successToast(result.message);
        navigate(Routes.Login);
      },
      (error) => {
        apiErrorToast(error);
      },
      (validationError) => {
        if (validationError.hasError('token')) {
          const tokenErrorMessages = validationError.getForField('token');
          tokenErrorMessages.forEach((tokenErrorMessage) =>
            errorToast(tokenErrorMessage.message)
          );
        }
      }
    );

  const completePasswordReset = async () => {
    const data = {
      token: query.get('passwordRecoveryToken'),
      newPassword: password,
      newPasswordRepeat: passwordRepeat,
    };

    await executeRequest(data);
  };

  return (
    <Container maxWidth={maxWidth.s}>
      <h3>Vergib jetzt dein neues Passwort:</h3>
      <Form onSubmit={() => completePasswordReset()}>
        <Input
          type="password"
          value={password}
          onChange={(password) => setPassword(password)}
          label="Passwort"
          placeholder="Gib hier dein neues Passwort ein"
          error={validationError?.getForField('newPassword') ?? []}
          required={true}
        />
        <Input
          type="password"
          value={passwordRepeat}
          onChange={(passwordRepeat) => setPasswordRepeat(passwordRepeat)}
          label="Passwort bestätigen"
          placeholder="Bestätige hier dein neues Passwort"
          error={validationError?.getForField('newPasswordRepeat') ?? []}
          required={true}
        />
        <Button loading={loading} type="submit">
          Passwort ändern
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordReset;
