import styled from 'styled-components';

export const Root = styled.div`
  border: 1px solid black;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Article = styled.div`
  cursor: pointer;
  margin: 0.5rem;
  display: flex;
  max-width: 100px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
