import { useEffect, useState } from 'react';

import {
  deleteLocalStorageAuthorization,
  getLocalStorageAuthorization,
  setLocalStorageAuthorization,
} from './LocalStorage';

export function useGlobalContextHelper() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [authorizationHeader, setAuthorizationHeader] = useState<
    string | undefined
  >(undefined);

  // initial calculation of global context values
  useEffect(() => {
    const localStorageAuthorization = getLocalStorageAuthorization();

    setLoggedIn(!!localStorageAuthorization);
    setAuthorizationHeader(localStorageAuthorization ?? undefined);
    setIsLoading(false);
  }, []);

  const login = (authorizationHeader: string) => {
    setLoggedIn(true);
    setAuthorizationHeader(authorizationHeader);
    setLocalStorageAuthorization(authorizationHeader);
  };

  const logout = () => {
    setAuthorizationHeader(undefined);
    deleteLocalStorageAuthorization();
    setLoggedIn(false);
  };

  return {
    isLoading,
    authorizationHeader,
    loggedIn: loggedIn ?? false,
    login,
    logout,
  };
}
