import styled from 'styled-components';

export const Root = styled.div``;

export const DropzoneContainer = styled.div`
  border: 1px #000 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 50px;
  margin-bottom: 2rem;
  text-align: center;
`;
