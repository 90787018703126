import React from 'react';

import { PropsWithClassName } from '@Helper/PropsWithClassName';

import { BadgeRoot } from './Badge.styles';

interface Props extends PropsWithClassName {
  children: string;
  variant?: 'primary' | 'success' | 'error' | 'warning' | 'secondary';
}

const Badge = (props: Props): React.ReactElement => {
  const { children, variant } = props;

  return (
    <BadgeRoot
      className={props.className}
      data-testid={props.dataTestId}
      variant={variant ?? 'primary'}
    >
      {children}
    </BadgeRoot>
  );
};

export default Badge;
