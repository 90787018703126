import styled from 'styled-components';

export const FaqLi = styled.li`
  cursor: pointer;
`;

export const LiContainer = styled.div`
  display: flex;
  align-items: center;
`;
