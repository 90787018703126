import { IconTrash } from '@tabler/icons';
import styled from 'styled-components';

export const Root = styled.span`
  position: relative;
  margin: 0.5rem;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Preview = styled.img`
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
`;

export const DeleteButton = styled(IconTrash)`
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  background-color: red;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
  transform: translateX(20%) translateY(-20%);
`;
